import React from "react";

import countryName from "../components/countryName";
import flag from "country-code-emoji";

const Location = ({ location, index }) =>
    <div className={`sm:flex mr-2 sm:mx-2 mt-2 sm:mt-0 flex items-center leading-5 text-gray-300 sm:mt-0 ${(index === 0) ? "" : "sm:pl-4 sm:border-l"}`}>
        <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
        <span>{countryName(location)}</span>
        <span className="text-xl ml-3">{flag(location)}</span>
    </div>

const LocationWithHiringText = ({ location, index }) =>
    <div className={`sm:flex mr-2 sm:mx-2 mt-2 sm:mt-0 flex items-center leading-5 text-gray-300 sm:mt-0 ${(index === 0) ? "" : "sm:pl-4 sm:border-l"}`}>
        <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
        <span>{location.text}</span>
        {location.flag ?
            <span className="text-xl ml-3">{flag(location.flag)}</span> : <></>
        }
    </div>

export default Location;

export { LocationWithHiringText };