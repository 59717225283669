import React from "react";

import companies from "./companies";
import Tags from "../components/tag";
import Location, { LocationWithHiringText } from "../components/companyLocation";
import { companyLink } from "./links";
import cms from "./cms/cms";

const formURL = cms.keyword === "Rust" ? "https://forms.gle/q8qxsqpcFH8VCLo8A" : "https://forms.gle/JZiv9qgfCdasDVfN7";

const CompanyList = ({ companies }) => <ul>
    {companies.map(company => <Company key={company.id} company={company} />)}
</ul>

const YourCompany = () =>
    <div className="bg-gradient-to-br from-gray-900 to-teal-800 shadow-lg overflow-hidden sm:rounded-md mt-5">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
            <div className="lg:w-0 lg:flex-1">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-red-500 sm:text-4xl sm:leading-10">
                    <span>Is your company hiring {cms.keyword} Engineers?</span>
                </h2>
                <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
                    Promote your company to thousands of {cms.keyword} developers who are actively looking for jobs.
                </p>
            </div>
            <div className="mt-8 lg:mt-0 lg:ml-8">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={formURL}
                    className="cursor-pointer sm:w-auto w-full flex items-center justify-center px-4 py-2 text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                    <span>Start Hiring</span>
                </a>
            </div>
        </div>
    </div>


const Company = ({ company }) =>
    <li className="bg-gradient-to-br from-gray-900 to-teal-800 shadow-lg overflow-hidden sm:rounded-md mt-5">
        <div className="px-4 pb-4 sm:px-6">
            <div className="flex-shrink-0 flex -ml-2 py-4 overflow-x-auto">
                <a
                    href={companyLink(company.id)} eventCategory="Featured Company" eventAction="Click" eventLabel={company.id}
                    className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-yellow-300 hover:bg-yellow-200 text-yellow-900 mr-2">
                    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
                    <span>Featured Company</span>
                </a>
                <a
                    href={companyLink(company.id)} eventCategory="Community Sponsor" eventAction="Click" eventLabel={company.id}
                    className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-red-500 hover:bg-red-400 text-red-900 mr-2">
                    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                    <span>Community Sponsor</span>
                </a>
                {
                    // TODO FIX
                }
                {/* <Remoteness remote={company.remote} /> */}
            </div>

            <div className="sm:flex sm:items-center sm:justify-between">
                <div>
                    <a
                        className="flex items-center"
                        href={companyLink(company.id)} eventCategory="Featured Company Title" eventAction="Click" eventLabel={company.id}>
                        {company.logo ? <img className="h-8 w-8 mr-2" src={company.logo} alt={company.name} /> : <></>}
                        <h1 className="text-3xl leading-5 font-bold text-red-500 hover:text-red-400">
                            {company.name}
                        </h1>
                    </a>
                    <div className="mt-2 text-gray-300">
                        <div className="sm:flex items-center">
                            <span className="font-medium shrink-0">
                                Hiring in:
                            </span>
                            {company.hiringText ?
                                <div className="sm:flex items-center">{company.hiringText.map((location, index) => <LocationWithHiringText key={location.text} location={location} index={index} />)} </div> :
                                <div className="sm:flex items-center">{company.locations ? company.locations.map((location, index) => <Location key={location} location={location} index={index} />) : <></>} </div>
                            }
                        </div>
                        <div className="sm:mt-0 mt-2">
                            <span className="font-medium shrink-0 sm:mb-0 mb-4 mr-2">Company size:</span>
                            <span className="mr-1">{company.size} Developers</span>
                        </div>
                        <Tags jobTags={company.tags} />
                    </div>
                </div>
            </div>
        </div>
    </li>

const FeaturedCompanies = ({ countries }) => {

    const countryList = Object.entries(companies).map(([key, company]) => company);
    const filteredCompanies = countries ? countryList.filter(company => company.locations.some(v => countries.includes(v))) : countryList;

    return (filteredCompanies.length > 0) ? <div className="mb-5">
        <h1 className="mt-5 text-center sm:py-2 text-3xl sm:text-4xl tracking-tight leading-10 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">
            <span>
                Companies
            </span>
        </h1>
        <CompanyList companies={filteredCompanies} />
        <YourCompany />
    </div> : <></>;
}


export default FeaturedCompanies;